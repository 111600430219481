<template>
    <section id="about-me">
        <div class="py-12"></div>
        <v-container class="text-center">
            <h2 class="display-2 font-weight-bold mb-3">ABOUT ME</h2>
            <v-responsive class="mx-auto mb-8" width="56">
                <v-divider class="mb-1"></v-divider>
                <v-divider></v-divider>
            </v-responsive>
            <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
                Hello! My name is Gian Pierree but you can call me GP. I am a web developer and I worked almost 7 years with <Icon title="PHP" titleIcon="language-php" />, but now I have almost 5 years working with <Icon title="NodeJS" titleIcon="nodejs" /> and with different frameworks like <Icon title="VueJS" titleIcon="vuejs" /> <Icon title="React" titleIcon="react" /> <Icon title="Angular" titleIcon="angular" />. <br />
                I also have experience managing servers in <Icon title="Amazon Web Service" titleIcon="aws" /> <Icon title="Google Cloud" titleIcon="google-cloud" /> <Icon title="Azure" titleIcon="microsoft-azure" /> and with CRM or ERP management systems.
            </v-responsive>
            <div class="text-center">
                <v-btn href="https://www.linkedin.com/in/gianpierree/" target="_blank" class="ma-2" fab icon text color="blue">
                    <v-icon size="40"> mdi-linkedin </v-icon>
                </v-btn>
                <v-btn href="https://github.com/GianPierree" target="_blank" class="ma-2" fab icon text color="black lighten-2">
                    <v-icon size="40"> mdi-github </v-icon>
                </v-btn>
            </div>
        </v-container>
        <div class="py-12"></div>
    </section>
</template>
<script>
import Icon from "@/components/Icon/Icon";
export default {
    name: "About",
    components: {
        Icon,
    },
    data() {
        return {
            hoverLinkedin: false,
            hoverGit: false,
        };
    },
};
</script>
