<template>
    <v-badge :value="hover" color="grey darken-3" :content="title" left transition="slide-x-transition">
        <v-hover v-model="hover">
            <v-icon large> mdi-{{ titleIcon }} </v-icon>
        </v-hover>
    </v-badge>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "",
        },
        titleIcon: {
            type: String,
            default: "",
        },
    },
    name: "Icon",
    components: {},
    data() {
        return {
            hover: false,
        };
    },
};
</script>