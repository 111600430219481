<template>
    <div id="skills">
        <section id="features" class="grey lighten-3">
            <div class="py-12"></div>
            <v-container class="text-center">
                <h2 class="display-2 font-weight-bold mb-3">MY SKILLS</h2>
                <v-responsive class="mx-auto mb-12" width="56">
                    <v-divider class="mb-1"></v-divider>
                    <v-divider></v-divider>
                </v-responsive>
                <v-row>
                    <v-col v-for="({ icon, title, text, skills }, i) in features" :key="i" cols="12" md="3">
                        <v-card class="py-12 px-4" :min-height="skillsHeight" color="grey lighten-5" flat>
                            <v-theme-provider dark>
                                <div>
                                    <v-avatar color="#c40f0f" size="88">
                                        <v-icon x-large v-text="icon"></v-icon>
                                    </v-avatar>
                                </div>
                            </v-theme-provider>
                            <v-card-title class="justify-center font-weight-black text-uppercase" v-text="title"></v-card-title>
                            <v-card-text class="subtitle-1" v-text="text"> </v-card-text>
                            <v-row v-show="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg" >
                                <v-col v-for="({ icon }, i) in skills" :key="i" cols="12" md="4" sm="3">
                                    <v-icon x-large v-text="icon"></v-icon>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <div class="py-12"></div>
        </section>
        <!-- <Projects /> -->
    </div>
</template>
<script>
// import Projects from './Projects.vue'
export default {
    name: "Skills",
    components: {
        // Projects,
    },
    data() {
        return {
            features: [
                {
                    icon: "mdi-language-html5",
                    title: "FrontEnd",
                    text: "I have experience with different JS frameworks. Currently working on VueJs and i have used Astro.",
                    skills: [
                        {
                            name: "VueJs",
                            icon: "mdi-vuejs",
                        },
                        {
                            name: "React",
                            icon: "mdi-react",
                        },
                        {
                            name: "Angular",
                            icon: "mdi-angular",
                        },
                        {
                            name: "Javascript",
                            icon: "mdi-language-javascript",
                        },
                        {
                            name: "HTML5",
                            icon: "mdi-language-html5",
                        },
                        {
                            name: "CSS3",
                            icon: "mdi-language-css3",
                        },
                    ]
                },
                {
                    icon: "mdi-console",
                    title: "BackEnd",
                    text: "I have experience working with PHP and currently working on NodeJS using Express. I've been practicing with Deno.",
                    skills: [
                        {
                            name: "NodeJS",
                            icon: "mdi-nodejs",
                        },
                        {
                            name: "PHP",
                            icon: "mdi-language-php",
                        },
                        {
                            name: "Laravel",
                            icon: "mdi-laravel",
                        },
                    ]
                },
                {
                    icon: "mdi-server",
                    title: "Server",
                    text: "I have experience managing Linux servers on AWS or Google Cloud. I've been working with Docker.",
                    skills: [
                        {
                            name: "Linux",
                            icon: "mdi-linux",
                        },
                        {
                            name: "Ubuntu",
                            icon: "mdi-ubuntu",
                        },
                        {
                            name: "AWS",
                            icon: "mdi-aws",
                        },
                        {
                            name: "Google Cloud",
                            icon: "mdi-google-cloud",
                        },
                        {
                            name: "Azure",
                            icon: "mdi-microsoft-azure",
                        },
                        {
                            name: "Docker",
                            icon: "mdi-docker",
                        },
                    ]
                },
                {
                    icon: "mdi-database",
                    title: "Database",
                    text: "I have experience working with relational and non-relational databases. I have also used Firebase.",
                    skills: [
                        {
                            name: "Firebase",
                            icon: "mdi-firebase",
                        },
                    ]
                },
            ],
        };
    },
    computed:{
        skillsHeight() {
            if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md){
                return 500
            }else{
                return 200
            }
        }
    }
};
</script>
