<template>
    <v-parallax id="home" height="600" src="@/assets/img/home2.jpeg">
        <v-theme-provider dark>
            <v-container class="container">
                <v-row align="center" class="white--text">
                    <v-col class="white--text text-left pl-16" :cols="numCol" tag="h1">
                        <span class="text-md-h3 text-xl-h3 text-lg-h3">
                            <p>Hi 👋</p>
                            <vue-typed-js :strings="['FullStack Developer', 'Gian Muñoz']" :loop="true">
                                <p>I'm <span class="typing typewriter"></span></p>
                            </vue-typed-js>
                        </span>
                        <div v-if="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg">
                            <v-btn class="align-self-end mr-10" outlined @click="$vuetify.goTo('#about-me')"> Know me <v-icon>mdi-chevron-double-down</v-icon> </v-btn>
                            <v-btn class="align-self-end" outlined @click="$vuetify.goTo('#contact')"> Work with me <v-icon>mdi-web</v-icon> </v-btn>
                        </div>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.xl || $vuetify.breakpoint.lg" class="white--text text-right pt-16" cols="5" tag="h1">
                        <v-img src="@/assets/img/avatar2.png" lazy-src="@/assets/img/avatar2.png" aspect-ratio="1.5" width="700"> </v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-theme-provider>
    </v-parallax>
</template>

<script>

export default {
    name: "Home",
    components: {},
    computed: {
        numCol(){
            if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md){
                return 7
            }else{
                return 12
            }
        }
    },
    methods: {
    }
};
</script>

<style scoped>
.typewriter {
    color: #c40f0f;
    font-weight: bold;
    font-size: 2.5rem;
}
.container {
    width: 60%;
}
</style>