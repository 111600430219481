<template>
    <v-parallax :height="numHeight" src="https://images.unsplash.com/photo-1510915228340-29c85a43dcfe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80">
        <v-container id="contact" class="text-center">
            <h2 class="display-2 font-weight-bold mx-auto mb-10">CONTACT ME</h2>
            <v-row>
                <v-col :cols="numCol">
                    <span class="overline ">Let me know your idea and let's work together.</span><br />
                    <span class="overline ">Or just <v-btn :href="linkCoffe" target="_blank" color="#82B1FF"><v-icon small>mdi-coffee</v-icon> Invite me a coffee</v-btn></span>
                    <br />
                    <v-row class="mt-10">
                        <v-col v-for="({ icon, link }, i) in socials" :key="i" cols="4" class="mt-1">
                            <v-btn :href="link" target="_blank" class="ma-2" fab icon text color="#E0E0E0">
                                <v-icon x-large v-text="icon"></v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col :cols="numCol">
                    <form>
                        <v-text-field v-model="name" :error-messages="nameErrors" :counter="20" label="Name" required @input="$v.name.$touch()" @blur="$v.name.$touch()" prepend-icon="mdi-account"></v-text-field>
                        <v-text-field v-model="email" :error-messages="emailErrors" label="E-mail" required @input="$v.email.$touch()" @blur="$v.email.$touch()" prepend-icon="mdi-email"></v-text-field>
                        <v-textarea :counter="100" label="Text" :rules="rules" v-model="value" prepend-icon="mdi-text"></v-textarea>
                        <v-btn @click="submit" min-width="100" color="primary">
                            <v-progress-circular v-if="loading" indeterminate color="grey"></v-progress-circular>
                            <div v-else>
                                <v-icon left> mdi-email-fast </v-icon>Send
                            </div>
                        </v-btn>
                    </form>
                </v-col>
            </v-row>
        </v-container>
    </v-parallax>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required, maxLength, email } from "vuelidate/lib/validators";
import { API } from 'aws-amplify'

export default {
    name: "Contact",
    mixins: [validationMixin],
    validations: {
        name: { required, maxLength: maxLength(20) },
        email: { required, email },
    },
    data() {
        return {
            socials: [
                { icon: "mdi-facebook", link: "https://www.facebook.com/gpsito/" },
                { icon: "mdi-twitter", link: "https://twitter.com/gianpierree" },
                { icon: "mdi-instagram", link: "https://www.instagram.com/gianpierree/" },
                { icon: "mdi-linkedin", link: "https://www.linkedin.com/in/gianpierree/" },
                { icon: "mdi-github", link: "https://github.com/GianPierree" },
                { icon: "mdi-youtube", link: "https://www.youtube.com/channel/UCV4_DBbprHn-ygb-_krawUw" },
            ],
            linkCoffe: "https://www.buymeacoffee.com/GianPierree",
            name: "",
            email: "",
            rules: [(v) => v.length <= 100 || "Max 100 characters"],
            value: "",
            loading: false
        };
    },
    computed: {
        nameErrors() {
            const errors = [];
            if (!this.$v.name.$dirty) return errors;
            !this.$v.name.maxLength && errors.push("Name must be at most 10 characters long");
            !this.$v.name.required && errors.push("Name is required.");
            return errors;
        },
        emailErrors() {
            const errors = [];
            if (!this.$v.email.$dirty) return errors;
            !this.$v.email.email && errors.push("Must be valid e-mail");
            !this.$v.email.required && errors.push("E-mail is required");
            return errors;
        },
        numCol(){
            if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md){
                return 6
            }else{
                return 12
            }
        },
        numHeight(){
            if(this.$vuetify.breakpoint.xl || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.md){
                return 800
            }else{
                return 1000
            }
        }
    },

    methods: {
        async submit() {
            let name = process.env.VUE_APP_API_NAME_SEND_MAIL
            let path = process.env.VUE_APP_API_PATH_SEND_MAIL
            this.$v.$touch();
            if (!this.$v.$invalid) {
                try{
                    let obj = { 
                        // headers: {},
                        response: true,
                        queryStringParameters: { 
                            name: this.name,
                            email: this.email,
                            desc: this.value,
                        },
                    };
                    
                    this.loading = true
                    let result = await API.get(name, path, obj) 
                    if(result.status != 200) throw "EMAIL_NOT_SEND"                  
                    console.log("Mensaje enviado")
                    this.clear();
                    this.$vuetify.goTo('#home')
                    this.loading = false
                }catch (e) {
                    console.log("error: ", e)
                }
            }
        },
        clear() {
            this.$v.$reset();
            this.name = "";
            this.email = "";
            this.value = "";
        },
    },
};
</script>
<style scoped>
form {
    background-color: rgba(224, 224, 224, 0.7);
    padding: 20px;
    border-radius: 25px;
}

.v-progress-circular {
    margin: 1rem;
}

.progressLoading{
    position: relative;
    border: solid 1px red;
    width: 100% !important;
    height: 200px !important;
}
</style>
