var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"skills"}},[_c('section',{staticClass:"grey lighten-3",attrs:{"id":"features"}},[_c('div',{staticClass:"py-12"}),_c('v-container',{staticClass:"text-center"},[_c('h2',{staticClass:"display-2 font-weight-bold mb-3"},[_vm._v("MY SKILLS")]),_c('v-responsive',{staticClass:"mx-auto mb-12",attrs:{"width":"56"}},[_c('v-divider',{staticClass:"mb-1"}),_c('v-divider')],1),_c('v-row',_vm._l((_vm.features),function(ref,i){
var icon = ref.icon;
var title = ref.title;
var text = ref.text;
var skills = ref.skills;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"3"}},[_c('v-card',{staticClass:"py-12 px-4",attrs:{"min-height":_vm.skillsHeight,"color":"grey lighten-5","flat":""}},[_c('v-theme-provider',{attrs:{"dark":""}},[_c('div',[_c('v-avatar',{attrs:{"color":"#c40f0f","size":"88"}},[_c('v-icon',{attrs:{"x-large":""},domProps:{"textContent":_vm._s(icon)}})],1)],1)]),_c('v-card-title',{staticClass:"justify-center font-weight-black text-uppercase",domProps:{"textContent":_vm._s(title)}}),_c('v-card-text',{staticClass:"subtitle-1",domProps:{"textContent":_vm._s(text)}}),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.xl || _vm.$vuetify.breakpoint.lg),expression:"$vuetify.breakpoint.xl || $vuetify.breakpoint.lg"}]},_vm._l((skills),function(ref,i){
var icon = ref.icon;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4","sm":"3"}},[_c('v-icon',{attrs:{"x-large":""},domProps:{"textContent":_vm._s(icon)}})],1)}),1)],1)],1)}),1)],1),_c('div',{staticClass:"py-12"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }