<template>
    <v-content>
        <Home />
        <About />
        <Skills />
        <Contact />
    </v-content>
</template>
<script>
import Home from "./Home.vue";
import About from "./About.vue";
import Skills from "./Skills.vue";
import Contact from "./Contact.vue";

export default {
    name: "Container",
    components: {
        About,
        Home,
        Skills,
        Contact,
    },
    data() {
        return {
            
        };
    },    
}
</script>